<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>PTC Logs</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                      
              </CCol>
              <CCol md="2">
                    <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>                   
              </CCol>             
          </CRow>
        </CCardHeader><br/>
        <CCardBody>
          <CRow>
              <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
          </CRow>

          <div class="text-center">
             <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
          </div>

                    <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                     </b-card-group>


          <div class="table-responsive table">
          <b-table striped hover  :items="items" :fields="fields" sort-by="createDate" sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">
          
            <!-- <template v-slot:cell(request)="{ item }"> 
                 <span @click="toggle(item.id)">Show</span>
                 <span v-if="opened.includes(item.id)">
                     {{ item.request }}
                 </span>
            </template>   -->

             <!-- <template v-slot:cell(response)="{ item }"> 
                 <span @click="toggle(item.id)">Show</span>
                 <span v-if="opened.includes(item.id)">
                     {{ item.response }}
                 </span>
            </template>  -->

            <template v-slot:cell(actions)="{ item }"> 
                  <span @click="toggle(item.id)" style="color:blue;">Detail</span><br/><br/>
                  <span v-if="opened.includes(item.id)">
                     REQUEST  : <br/>{{ item.request }} <br/><br/>
                     RESPONSE : <br/>{{ item.response }} <br/>
                  </span>
            </template>            

            <template v-slot:cell(createDate)="{ item }"> 
                 {{ item.createDate | dateWithTime }}
            </template> 

        </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import TransactService from '@/api/TransactService.js';
import ReportService from '@/api/ReportService.js';
import moment from 'moment';

 export default {
    name: "ptc_logs",
    data() { var date = new Date();
      return { 
        opened: [],
        items:[],             
        fields: [   
          {
            key: 'createDate',
            label : 'Date Time',
            sortable: true
          },      
          {            
            key: 'movementRef',
            label : 'Movement Ref',
            tdClass: 'nameOfTheClass',
            sortable: true
          }, 
          {
            key: 'requestUrl',
            label : 'Request Url',
            tdClass: 'nameOfTheClass',
            sortable: true
          }, 
          {            
            key: 'actions',
            tdClass: 'nameOfTheClass2',
            label: 'Actions'         
          },           
          // {
          //   key: 'request',
          //   label : 'Request',
          //   tdClass: 'nameOfTheClass',
          //   sortable: true
          // },  
          // {
          //   key: 'response',
          //   label : 'Response',
          //   tdClass: 'nameOfTheClass',
          //   sortable: true
          // },                 
        ], 
        data:[],
        columns:[], 
        formPasswd:false,
        filename:"ptc-logs.xlsx",
        password:"",    
        currentPage: 1,
        perPage: 10,
        filter: null,
        seen:false,
        msg :'',
        color:'', 
        spinner:false,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        passOnlyString(value){
           if(!value) return ''
           value = value.toString()
           return value.replace(/[^a-zA-Z ]/g, "")
        },  
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        setlabel(value){
           if(value) return "Yes"
           else return "No"     
        }
    },

    created () {       
      this.getRequestResponseRecord(); 
      this.setDefaultPasswd(); //this.setColumns();
    },
    methods: { 
      toggle(id) {
          console.log(id);
          const index = this.opened.indexOf(id);
          if (index > -1) {
            this.opened.splice(index, 1)
          } else {
            this.opened.push(id)
          }
      },
        
      showFormPasswd(){
          this.formPasswd = true;
      },

      actionCancel(){
            this.formPasswd = false;
      },
        
      setDefaultPasswd(){
          var today = moment().format('DDMMYYYYHHmmss');
          var user  = this.$store.getters.getUser; 
          this.password = user+today;
      },

      setColumns(){ 
        this.columns = [];
        var fields = this.fields; let row = {}; var cols = [];
        var except = ['index','details','actions'];
        var resp = { key: 'response',label : 'Response'};
        var req = { key: 'request', label : 'Request'};

        fields.push(req, resp);

        for (var key in fields) {  
           var vkey = fields[key].key;
           if(!except.includes(vkey)){         
             row['label'] = fields[key].label;
             row['field'] = fields[key].key;
             var temp = JSON.stringify(row); cols.push(temp);
           }
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
      },

      setDataRows(resp,keydate){
            
            for (var key in resp) {                
              var resp2 = resp[key];  
                for (var key2 in resp2) { 
                    if(key2 === keydate){ 
                      var strdate = resp2[key2]; 
                      var dt = new Date(strdate).toISOString().substr(0, 19).replace("T"," ");
                      //console.log(dt);
                      resp[key][keydate] = strdate.substr(0, 19).replace("T"," ");                     
                    }                   
                 }
            }           

          this.data  = resp;               
      },

      generateWorkbook(){

          this.setColumns();

          this.formPasswd = false; this.spinner=true;
          var cols  = this.columns; var rows  = this.data;        
          var heads = []; var temp =[]; var data=[];
          let params = {};   var colkey =[];      

            for (var key in cols) {
              var col = cols[key]; heads.push(col.label);
              colkey.push(col.field);
            } 
            data.push(heads);  var jum = heads.length; 

            for (var key2 in rows) { var row = rows[key2]; 
                var init = Array(jum).fill(null);
                for(var key4 in colkey){
                  for (var key3 in row) { 
                    var val  = row[key3]; 
                    // if(key3==="lastLogin"){
                    //   var val = new Date(val).toISOString().substr(0, 19).replace("T"," ");
                    // }                
                    var ckey = colkey[key4]; 
                    if(key3===ckey){ 
                      init[key4] = val; 
                    }              
                  }
                }
                data.push(init); 
            } 

          var ccols = this.fields;
          let index = ccols.findIndex(ccols => ccols.key === 'request' );
          ccols.splice(index,1);

          let index2 = ccols.findIndex(ccols => ccols.key === 'response' );
          ccols.splice(index2,1);
          

          params['password'] = this.password;
          params['data'] = data;   //console.log(params); 
          this.getReportExcel(params);
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click();  this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {  
            console.log(err);            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 
      
      getRequestResponseRecord: function() {
        TransactService.getRequestResponseRecord().then(resp => {  //console.log(resp);
            this.items = resp; 
            
            this.setDataRows(resp,"createDate");
            
        }, error => {
          this.spinner = false;
        });
      },

    }    
 }; 

</script>
<style>    
    .nameOfTheClass {
      max-width: 150px;
    },
    .nameOfTheClass2 {
      max-width: 180px;
    }
</style>